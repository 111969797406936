// import { EngineSDK } from '@dilvant/engine-sdk';
// import { SDK } from '@dilvant/bizcard-sdk';
import { log } from 'console';
import { countDown } from './js/countdown';
// import AOS from 'aos';

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// AOS.init();

// SDK.setEnv("prod");
// EngineSDK.setEnv("prod");

countDown();

$('.autoplay').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	infinite: true,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
});

let containerGallery = document.getElementsByClassName('slick-track');
if (!containerGallery || containerGallery.length === 0) return;
containerGallery[0].classList.add('lightgallery');
containerGallery.id = 'lightgallery';

document.addEventListener('DOMContentLoaded', function () {
	lightGallery(containerGallery[0], {
		plugins: [lgZoom, lgThumbnail],
		licenseKey: 'your_license_key',
		speed: 500
	});
});

const audio = document.getElementById('audio');

const playButton = document.getElementById('musicBtn');
const pause = document.getElementById('pause');

let isPlaying = false;

playButton.addEventListener('click', () => {
	if (isPlaying) {
		audio.pause();
		pause.classList.add('pause');
	} else {
		audio.play();
		pause.classList.remove('pause');
	}
	isPlaying = !isPlaying;
});
